import { template as template_678015663200475b92a0cf28b922dc1a } from "@ember/template-compiler";
const WelcomeHeader = template_678015663200475b92a0cf28b922dc1a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
