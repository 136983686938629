import { template as template_65f39aa415c44a98a0f0bece118ba032 } from "@ember/template-compiler";
const FKControlMenuContainer = template_65f39aa415c44a98a0f0bece118ba032(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
