import { template as template_1c5a01d99c944dc380906cbbf75cedab } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_1c5a01d99c944dc380906cbbf75cedab(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
