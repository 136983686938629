import { template as template_00e484f822b34cd69b8b6b0a3e0e4482 } from "@ember/template-compiler";
const SidebarSectionMessage = template_00e484f822b34cd69b8b6b0a3e0e4482(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
