import { template as template_2e3ffc2b2629441fa46ab55d14855953 } from "@ember/template-compiler";
const FKLabel = template_2e3ffc2b2629441fa46ab55d14855953(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
